html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline;}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block;}body{line-height:1;}ol,ul{list-style:none;}blockquote,q{quotes:none;}blockquote:before,blockquote:after,q:before,q:after{content:'';content:none;}table{border-collapse:collapse;border-spacing:0;}body{-webkit-text-size-adjust:none}mark{background-color:transparent;color:inherit}input::-moz-focus-inner{border:0;padding:0}input[type="text"],input[type="email"],select,textarea{-moz-appearance:none;-webkit-appearance:none;-ms-appearance:none;appearance:none}

*, *:before, *:after {
	box-sizing: border-box;
}

body {
	line-height: 1.0;
	min-height: var(--viewport-height);
	min-width: 320px;
	overflow-x: hidden;
	word-wrap: break-word;
	background-color: #171A1C;
}

body:before {
	background-attachment: scroll;
	content: '';
	display: block;
	height: var(--background-height);
	left: 0;
	pointer-events: none;
	position: fixed;
	top: 0;
	transform: scale(1);
	width: 100vw;
	z-index: 0;
	/* background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20512%22%20width%3D%22512%22%20height%3D%22512%22%20preserveAspectRatio%3D%22none%22%3E%20%3Cstyle%20type%3D%22text%2Fcss%22%3E%20path%20%7B%20fill%3A%20none%3B%20stroke%3A%20rgba(255,255,255,0.059)%3B%20stroke-width%3A%201.01px%3B%20stroke-linecap%3A%20round%3B%20stroke-linejoin%3A%20round%3B%20%7D%20%3C%2Fstyle%3E%20%3Cpath%20vector-effect%3D%22non-scaling-stroke%22%20d%3D%22M416%2C192L256%2C320L96%2C192L256%2C64L416%2C192z%20M96-64L256%2C64L416-64%20M416%2C576V448L256%2C576%20M256%2C576L96%2C448v128%20M512%2C191L512%2C191L352%2C320l160%2C128.3L672%2C320L352%2C64l320-256%20M512%2C191L672%2C64L352-192%20M160-192L-160%2C64l320%2C256L0%2C448.3%20M0%2C448.3%20L-160%2C320L160%2C64l-320-256%20M416%2C448L256%2C320L96%2C448%20M96-64v76.8%20M416-64v76.8%20M352%2C64v76.8%20M160%2C64v76.8%20M96%2C192v76.8%20M416%2C192%20l-0.5%2C76.8%20M352%2C320v76.8%20M160%2C320v76.8%20M160%2C115.2l15.8%2C13%20M352%2C115.2l-16.2%2C13%20M352%2C371.2l-16.2%2C13%20M160%2C371.2l16.2%2C13%20M671.5%2C575.4l-160-128l-160%2C128%20M159.5%2C575.4l-160-128l-160%2C128%22%20%2F%3E%3C%2Fsvg%3E'); */
	background-size: 676px;
	background-position: center;
	background-repeat: repeat;
}

body:after {
	background-color: #171A1C;
	content: '';
	display: block;
	height: 100%;
	left: 0;
	opacity: 0;
	pointer-events: none;
	position: fixed;
	top: 0;
	transform: scale(1);
	transition: opacity 1s ease-in-out 0s, visibility 1s 0s;
	visibility: hidden;
	width: 100%;
	z-index: 1;
}

body.is-loading:after {
	opacity: 1;
	visibility: visible;
}

:root {
	--background-height: 100vh;
	--site-language-alignment: left;
	--site-language-direction: ltr;
	--site-language-flex-alignment: flex-start;
	--site-language-indent-left: 1;
	--site-language-indent-right: 0;
	--site-language-margin-left: 0;
	--site-language-margin-right: auto;
	--viewport-height: 100vh;

	--color-background: #171A1C;
	--color-backgroundSecondary: rgba(62,71,87,0.6);

	--aspectRatio-heroImage: 0.562;
	--aspectRatio-showcaseImage: 1.359;
	--aspectRatio-socialProofImage: 1.815;
}

html {
	font-size: 18pt;
}

u {
	text-decoration: underline;
}

strong {
	color: inherit;
	font-weight: bolder;
}

em {
	font-style: italic;
}

code {
	background-color: rgba(144,144,144,0.25);
	border-radius: 0.25em;
	font-family: 'Lucida Console', 'Courier New', monospace;
	font-size: 0.9em;
	font-weight: normal;
	letter-spacing: 0;
	margin: 0 0.25em;
	padding: 0.25em 0.5em;
	text-indent: 0;
}

s {
	text-decoration: line-through;
}

sub {
	font-size: smaller;
	vertical-align: sub;
}

sup {
	font-size: smaller;
	vertical-align: super;
}

a {
	color: inherit;
	text-decoration: underline;
	transition: color 0.25s ease;
}

a[onclick]:not([href]) {
	cursor: pointer;
}

deferred-script {
	display: none;
}

#wrapper {
	-webkit-overflow-scrolling: touch;
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: var(--viewport-height);
	overflow: hidden;
	position: relative;
	z-index: 2;
}

#main {
	--alignment: center;
	--flex-alignment: center;
	--indent-left: 1;
	--indent-right: 1;
	--margin-left: auto;
	--margin-right: auto;
	--border-radius-tl: 0;
	--border-radius-tr: 0;
	--border-radius-br: 0;
	--border-radius-bl: 0;
	align-items: center;
	display: flex;
	flex-grow: 0;
	flex-shrink: 0;
	justify-content: center;
	max-width: 100%;
	position: relative;
	text-align: var(--alignment);
	z-index: 1;
	transition: opacity 0.5s ease-in-out 0s;
}

#main > .inner {
	--padding-horizontal: 2.25rem;
	--padding-vertical: 1.75rem;
	--spacing: 0rem;
	--width: 65rem;
	border-radius: var(--border-radius-tl) var(--border-radius-tr) var(--border-radius-br) var(--border-radius-bl);
	max-width: 100%;
	position: relative;
	width: var(--width);
	z-index: 1;
	padding: var(--padding-vertical) var(--padding-horizontal);
}

#main > .inner > * {
	margin-top: var(--spacing);
	margin-bottom: var(--spacing);
}

#main > .inner > :first-child {
	margin-top: 0 !important;
}

#main > .inner > :last-child {
	margin-bottom: 0 !important;
}

#main > .inner > .full {
	margin-left: calc(var(--padding-horizontal) * -1);
	max-width: calc(100% + calc(var(--padding-horizontal) * 2) + 0.4725px);
	width: calc(100% + calc(var(--padding-horizontal) * 2) + 0.4725px);
}

#main > .inner > .full:first-child {
	border-top-left-radius: inherit;
	border-top-right-radius: inherit;
	margin-top: calc(var(--padding-vertical) * -1) !important;
}

#main > .inner > .full:last-child {
	border-bottom-left-radius: inherit;
	border-bottom-right-radius: inherit;
	margin-bottom: calc(var(--padding-vertical) * -1) !important;
}

#main > .inner > .full.screen {
	border-radius: 0 !important;
	max-width: 100vw;
	position: relative;
	width: 100vw;
	left: 50%;
	margin-left: -50vw;
	right: auto;
}

body.is-loading #main {
	opacity: 0;
}

body #wrapper:after {
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	content: '';
	display: block;
	height: 4.125rem;
	left: calc(50% - 2.0625rem);
	opacity: 0;
	pointer-events: none;
	position: fixed;
	top: calc(50% - 2.0625rem);
	transition: opacity 1s ease, visibility 1s;
	visibility: hidden;
	width: 4.125rem;
	z-index: 100000;
}

body.is-loading {
	pointer-events: none;
}

body.is-loading.with-loader #wrapper:after {
	background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iOTZweCIgaGVpZ2h0PSI5NnB4IiB2aWV3Qm94PSIwIDAgOTYgOTYiIHpvb21BbmRQYW49ImRpc2FibGUiPjxzdHlsZT5jaXJjbGUgeyBmaWxsOiB0cmFuc3BhcmVudDsgc3Ryb2tlOiAjMDBGRkMyOyBzdHJva2Utd2lkdGg6IDJweDsgfTwvc3R5bGU+PGRlZnM+PGNsaXBQYXRoIGlkPSJjb3JuZXIiPjxwb2x5Z29uIHBvaW50cz0iMCwwIDQ4LDAgNDgsNDggOTYsNDggOTYsOTYgMCw5NiIgLz48L2NsaXBQYXRoPjwvZGVmcz48ZyBjbGlwLXBhdGg9InVybCgjY29ybmVyKSI+PGNpcmNsZSBjeD0iNDgiIGN5PSI0OCIgcj0iMzIiIHZlY3Rvci1lZmZlY3Q9Im5vbi1zY2FsaW5nLXN0cm9rZSIgLz48YW5pbWF0ZVRyYW5zZm9ybSBhdHRyaWJ1dGVOYW1lPSJ0cmFuc2Zvcm0iIGF0dHJpYnV0ZVR5cGU9IlhNTCIgdHlwZT0icm90YXRlIiBmcm9tPSIwIDQ4IDQ4IiB0bz0iMzYwIDQ4IDQ4IiBkdXI9IjFzIiByZXBlYXREdXI9ImluZGVmaW5pdGUiIC8+PC9nPjwvc3ZnPg==');
	opacity: 1;
	transform: scale(1.0);
	visibility: visible;
}

body.is-playing.with-loader #wrapper:after {
	opacity: 0;
	transform: scale(0.5);
	transition: opacity 0.25s ease, transform 0.75s ease, visibility 0.25s;
	visibility: hidden;
}

body.is-instant #main, body.is-instant #main > .inner > *,body.is-instant #main > .inner > section > *  {
	transition: none !important;
}

body.is-instant:after {
	display: none !important;
	transition: none !important;
}

@keyframes loading-spinner {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.deferred:before {
	content: '';
	display: block;
	width: 4rem;
	height: 4rem;
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -2rem 0 0 -2rem;
	animation: loading-spinner 1s infinite linear;
	transition: opacity 0.25s ease;
	transition-delay: 0.5s;
	opacity: 0;
	z-index: -1;
	background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iOTZweCIgaGVpZ2h0PSI5NnB4IiB2aWV3Qm94PSIwIDAgOTYgOTYiIHpvb21BbmRQYW49ImRpc2FibGUiPjxzdHlsZT5jaXJjbGUge2ZpbGw6IHRyYW5zcGFyZW50OyBzdHJva2U6ICNmZmY7IHN0cm9rZS13aWR0aDogMS41cHg7IH08L3N0eWxlPjxkZWZzPjxjbGlwUGF0aCBpZD0iY29ybmVyIj48cG9seWdvbiBwb2ludHM9IjAsMCA0OCwwIDQ4LDQ4IDk2LDQ4IDk2LDk2IDAsOTYiIC8+PC9jbGlwUGF0aD48L2RlZnM+PGcgY2xpcC1wYXRoPSJ1cmwoI2Nvcm5lcikiPjxjaXJjbGUgY3g9IjQ4IiBjeT0iNDgiIHI9IjMyIi8+PC9nPjwvc3ZnPg==');
	background-position: center;
	background-repeat: no-repeat;
	background-size: 3rem;
}

.deferred.loading:before {
	opacity: 0.35;
	z-index: 0;
}

h1, h2, h3, p {
	direction: var(--site-language-direction);
	position: relative;
}

h1 span.p, h2 span.p, h3 span.p, p span.p {
	display: block;
	position: relative;
}

h1 span[style], h2 span[style], h3 span[style], p span[style], h1 strong, h2 strong, h3 strong, p strong, h1 a, h2 a, h3 a, p a, h1 code, h2 code, h3 code, p code {
	-webkit-text-fill-color: currentcolor;
}

h1.style3:not(:first-child):not(:last-child), h2.style3:not(:first-child):not(:last-child), h3.style3:not(:first-child):not(:last-child) {
	margin-top: 1.125rem !important;
	margin-bottom: 1.125rem !important;
}


.style3 {
	max-width: 55.5rem;
	margin-left: var(--margin-left);
	margin-right: var(--margin-right);
	color: #FFFFFF;
	font-family: 'Inter', sans-serif;
	font-size: 3.6em;
	line-height: 1.3;
	font-weight: 600;
}


h1.style3 a, h2.style3 a, h3.style3 a, p.style3 a {
	text-decoration: underline;
}

h1.style3 a:hover, h2.style3 a:hover, h3.style3 a:hover, p.style3 a:hover {
	text-decoration: none;
}

h1.style3 span.p:nth-child(n + 2), h2.style3 span.p:nth-child(n + 2), h3.style3 span.p:nth-child(n + 2), p.style3 span.p:nth-child(n + 2) {
	margin-top: 1rem;
}

h1.style9:not(:first-child), h2.style9:not(:first-child), h3.style9:not(:first-child), p.style9:not(:first-child) {
	margin-top: 2.5rem !important;
}

h1.style9:not(:last-child), h2.style9:not(:last-child), h3.style9:not(:last-child), p.style9:not(:last-child) {
	margin-bottom: 2.5rem !important;
}

h1.style9, h2.style9, h3.style9, p.style9 {
	max-width: 36.25rem;
	margin-left: var(--margin-left);
	margin-right: var(--margin-right);
	color: rgba(255,255,255,0.971);
	font-family: 'Inter', sans-serif;
	font-size: 1.125em;
	line-height: 1.5;
	font-weight: 400;
}

h1.style9 a, h2.style9 a, h3.style9 a, p.style9 a {
	text-decoration: underline;
}

h1.style9 a:hover, h2.style9 a:hover, h3.style9 a:hover, p.style9 a:hover {
	text-decoration: none;
}

h1.style9 span.p:nth-child(n + 2), h2.style9 span.p:nth-child(n + 2), h3.style9 span.p:nth-child(n + 2), p.style9 span.p:nth-child(n + 2) {
	margin-top: 1rem;
}

h1.style4, h2.style4, h3.style4, p.style4 {
	text-transform: uppercase;
	color: rgba(255,255,255,0.439);
	font-family: 'Inter', sans-serif;
	font-size: 1em;
	line-height: 1.5;
	font-weight: 600;
}

h1.style4 a, h2.style4 a, h3.style4 a, p.style4 a {
	text-decoration: underline;
}

h1.style4 a:hover, h2.style4 a:hover, h3.style4 a:hover, p.style4 a:hover {
	text-decoration: none;
}

h1.style4 span.p:nth-child(n + 2), h2.style4 span.p:nth-child(n + 2), h3.style4 span.p:nth-child(n + 2), p.style4 span.p:nth-child(n + 2) {
	margin-top: 1rem;
}

h1.style1, h2.style1, h3.style1, p.style1 {
	color: #FFFFFF;
	font-family: 'Inter', sans-serif;
	font-size: 1.125em;
	line-height: 1.25;
	font-weight: 500;
}

h1.style1 a, h2.style1 a, h3.style1 a, p.style1 a {
	text-decoration: underline;
}

h1.style1 a:hover, h2.style1 a:hover, h3.style1 a:hover, p.style1 a:hover {
	text-decoration: none;
}

h1.style1 span.p:nth-child(n + 2), h2.style1 span.p:nth-child(n + 2), h3.style1 span.p:nth-child(n + 2), p.style1 span.p:nth-child(n + 2) {
	margin-top: 1rem;
}

h1.style6:not(:first-child), h2.style6:not(:first-child), h3.style6:not(:first-child), p.style6:not(:first-child) {
	margin-top: 1.5rem !important;
}

h1.style6:not(:last-child), h2.style6:not(:last-child), h3.style6:not(:last-child), p.style6:not(:last-child) {
	margin-bottom: 1rem !important;
}

h1.style6, h2.style6, h3.style6, p.style6 {
	text-align: left;
	color: #FFFFFF;
	font-family: 'Inter', sans-serif;
	font-size: 1.125em;
	line-height: 1.25;
	font-weight: 600;
}

h1.style6 a, h2.style6 a, h3.style6 a, p.style6 a {
	text-decoration: underline;
}

h1.style6 a:hover, h2.style6 a:hover, h3.style6 a:hover, p.style6 a:hover {
	text-decoration: none;
}

h1.style6 span.p:nth-child(n + 2), h2.style6 span.p:nth-child(n + 2), h3.style6 span.p:nth-child(n + 2), p.style6 span.p:nth-child(n + 2) {
	margin-top: 1rem;
}

h1.style5, h2.style5, h3.style5, p.style5, li.style5 {
	text-align: left;
	color: rgba(255,255,255,0.85);
	font-family: 'Inter', sans-serif;
	font-size: 0.875em;
	line-height: 1.5;
	font-weight: 00;
}

ul.style5 {
	list-style-type: disc; /* Ensures bullets are displayed */
	margin-top: 10px;
}

li.style5 {
	line-height: 2;
	margin-left: 1.5em; 
	/* margin-top: 10px; */
	
}

ol.style5 {
	list-style-type: decimal; /* Ensures numbers are displayed */
	margin-top: 10px;
	font-family: 'Inter', sans-serif;
	align-items: left;
}

h1.style5 a, h2.style5 a, h3.style5 a, p.style5 a {
	text-decoration: underline;
}

h1.style5 a:hover, h2.style5 a:hover, h3.style5 a:hover, p.style5 a:hover {
	text-decoration: none;
}

h1.style5 span.p:nth-child(n + 2), h2.style5 span.p:nth-child(n + 2), h3.style5 span.p:nth-child(n + 2), p.style5 span.p:nth-child(n + 2) {
	margin-top: 1rem;
}

h1.style2:not(:first-child), h2.style2:not(:first-child), h3.style2:not(:first-child), p.style2:not(:first-child) {
	margin-top: 2.5rem !important;
}

h1.style2:not(:last-child), h2.style2:not(:last-child), h3.style2:not(:last-child), p.style2:not(:last-child) {
	margin-bottom: 2.5rem !important;
}

h1.style2, h2.style2, h3.style2, p.style2 {
	max-width: 36.25rem;
	margin-left: var(--margin-left);
	margin-right: var(--margin-right);
	color: #FFFFFF;
	font-family: 'Inter', sans-serif;
	font-size: 1em;
	line-height: 1.5;
	font-weight: 300;
}

h1.style2 a, h2.style2 a, h3.style2 a, p.style2 a {
	text-decoration: underline;
}

h1.style2 a:hover, h2.style2 a:hover, h3.style2 a:hover, p.style2 a:hover {
	text-decoration: none;
}

h1.style2 span.p:nth-child(n + 2), h2.style2 span.p:nth-child(n + 2), h3.style2 span.p:nth-child(n + 2), p.style2 span.p:nth-child(n + 2) {
	margin-top: 1rem;
}

.buttons {
	cursor: default;
	display: flex;
	justify-content: var(--flex-alignment);
	letter-spacing: 0;
	padding: 0;
}

.buttons li {
	max-width: 100%;
	text-align: var(--alignment);
}

.buttons li a {
	align-items: center;
	justify-content: center;
	max-width: 100%;
	text-align: center;
	text-decoration: none;
	vertical-align: middle;
	white-space: nowrap;
}

.buttons.style2 {
	gap: 0.75rem;
	flex-direction: row;
	flex-wrap: wrap;
}

.buttons.style2:not(:first-child) {
	margin-top: 1.25rem !important;
}

.buttons.style2:not(:last-child) {
	margin-bottom: 1.25rem !important;
}

.buttons.style2 li a {
	display: inline-flex;
	width: auto;
	height: 3.5rem;
	line-height: 3.5rem;
	padding: 0 1.75rem;
	vertical-align: middle;
	font-family: 'Inter', sans-serif;
	font-size: 1em;
	font-weight: 600;
	border-radius: 2.5rem;
	flex-direction: row-reverse;
	transition: transform 0.25s ease, color 0.25s ease, background-color 0.25s ease, border-color 0.25s ease;
}

.buttons.style2 li a svg {
	display: block;
	fill: #FFFFFF;
	flex-grow: 0;
	flex-shrink: 0;
	height: 100%;
	min-width: 16px;
	width: 1em;
	margin-left: 0.75rem;
	margin-right: calc(-0.125em + 0rem);
	transition: fill 0.25s ease;
}

.buttons.style2 li a .label {
	direction: var(--site-language-direction);
	overflow: hidden;
}

.buttons.style2 .button {
	background-color: #007AFF;
	color: #FFFFFF;
}

.buttons.style2 .button svg {
	fill: #FFFFFF;
}

.buttons.style2 .button:hover {
	background-color: #007AFF !important;
}

.buttons.style2 li a:hover {
	transform: scale(1.0425);
}

.image {
	display: block;
	line-height: 0;
	max-width: 100%;
	position: relative;
}

.image .frame {
	-webkit-backface-visibility: hidden;
	-webkit-transform: translate3d(0, 0, 0);
	display: inline-block;
	max-width: 100%;
	overflow: hidden;
	vertical-align: top;
	width: 100%;
}

.image .frame img {
	border-radius: 0 !important;
	max-width: 100%;
	vertical-align: top;
	width: inherit;
}

.image.full .frame {
	display: block;
}

.image.full:first-child .frame {
	border-top-left-radius: inherit;
	border-top-right-radius: inherit;
}

.image.full:last-child .frame {
	border-bottom-left-radius: inherit;
	border-bottom-right-radius: inherit;
}

.image.style1 .frame {
	width: 96.875rem;
	aspect-ratio: var(--aspectRatio-heroImage);
	transition: none;
}

.image.style1 img {
	height: 100% !important;
	object-fit: cover;
	object-position: center;
	width: 100% !important;
}

.image.style1 .frame img {
	transition: none;
}

.image.style2 .frame {
	width: 44.375rem;
	aspect-ratio: var(--aspectRatio-showcaseImage);
	border-color: transparent;
	transition: none;
}

.image.style2 img {
	height: 100% !important;
	object-fit: cover;
	object-position: center;
	width: 100% !important;
}

.image.style2 .frame img {
	transition: none;
}

.image.style3 .frame {
	width: 43.375rem;
	aspect-ratio: var(--aspectRatio-socialProofImage);
	transition: none;
	margin-bottom: 0px;
}

.image.style3 img {
	height: 100% !important;
	object-fit: cover;
	object-position: center;
	width: 100% !important;
	margin-bottom: 0px;
}

.container {
	position: relative;
}

.container > .wrapper {
	vertical-align: top;
	position: relative;
	max-width: 100%;
	border-radius: inherit;
}

.container > .wrapper > .inner {
	vertical-align: top;
	position: relative;
	max-width: 100%;
	border-radius: inherit;
	text-align: var(--alignment);
}

.container.style2 {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	background-color: transparent;
}

.container.style2:not(:last-child) {
	margin-bottom: 1.75rem !important;
}

.container.style2 > .wrapper > .inner {
	--gutters: 0.5rem;
	--padding-horizontal: 1rem;
	--padding-vertical: 1rem;
	padding: var(--padding-vertical) var(--padding-horizontal);
}

.container.style2 > .wrapper {
	max-width: var(--width);
	width: 100%;
}

.container.style2.default > .wrapper > .inner > * {
	margin-bottom: var(--spacing);
	margin-top: var(--spacing);
}

.container.style2.default > .wrapper > .inner > *:first-child {
	margin-top: 0 !important;
}

.container.style2.default > .wrapper > .inner > *:last-child {
	margin-bottom: 0 !important;
}

.container.style2.columns > .wrapper > .inner {
	flex-wrap: wrap;
	display: flex;
	align-items: center;
}

.container.style2.columns > .wrapper > .inner > * {
	flex-grow: 0;
	flex-shrink: 0;
	max-width: 100%;
	text-align: var(--alignment);
	padding: 0 0 0 var(--gutters);
}

.container.style2.columns > .wrapper > .inner > * > * {
	margin-bottom: var(--spacing);
	margin-top: var(--spacing);
}

.container.style2.columns > .wrapper > .inner > * > *:first-child {
	margin-top: 0 !important;
}

.container.style2.columns > .wrapper > .inner > * > *:last-child {
	margin-bottom: 0 !important;
}

.container.style2.columns > .wrapper > .inner > *:first-child {
	margin-left: calc(var(--gutters) * -1);
}

.container.style2.default > .wrapper > .inner > .full {
	margin-left: calc(var(--padding-horizontal) * -1);
	max-width: none !important;
	width: calc(100% + (var(--padding-horizontal) * 2) + 0.4725px);
}

.container.style2.default > .wrapper > .inner > .full:first-child {
	margin-top: calc(var(--padding-vertical) * -1) !important;
	border-top-left-radius: inherit;
	border-top-right-radius: inherit;
}

.container.style2.default > .wrapper > .inner > .full:last-child {
	margin-bottom: calc(var(--padding-vertical) * -1) !important;
	border-bottom-left-radius: inherit;
	border-bottom-right-radius: inherit;
}

.container.style2.columns > .wrapper > .inner > div > .full {
	margin-left: calc(var(--gutters) * -0.5);
	max-width: none !important;
	width: calc(100% + var(--gutters) + 0.4725px);
}

.container.style2.columns > .wrapper > .inner > div:first-child > .full {
	margin-left: calc(var(--padding-horizontal) * -1);
	width: calc(100% + var(--padding-horizontal) + calc(var(--gutters) * 0.5) + 0.4725px);
}

.container.style2.columns > .wrapper > .inner > div:last-child > .full {
	width: calc(100% + var(--padding-horizontal) + calc(var(--gutters) * 0.5) + 0.4725px);
}

.container.style2.columns > .wrapper > .inner > div > .full:first-child {
	margin-top: calc(var(--padding-vertical) * -1) !important;
}

.container.style2.columns > .wrapper > .inner > div > .full:last-child {
	margin-bottom: calc(var(--padding-vertical) * -1) !important;
}

.container.style2.columns > .wrapper > .inner > .full {
	align-self: stretch;
}

.container.style2.columns > .wrapper > .inner > .full > .full:first-child:last-child {
	border-radius: inherit;
	height: calc(100% + (var(--padding-vertical) * 2));
}

.container.style2.columns > .wrapper > .inner > .full > .full:first-child:last-child > * {
	border-radius: inherit;
	height: 100%;
	position: absolute;
	width: 100%;
}

#container07 > .wrapper > .inner > :nth-child(1) {
	width: calc(60% + (var(--gutters) / 2));
	--alignment: left;
	--flex-alignment: flex-start;
	--indent-left: 1;
	--indent-right: 0;
	--margin-left: 0;
	--margin-right: auto;
}

#container07 > .wrapper > .inner > :nth-child(2) {
	width: calc(40% + (var(--gutters) / 2));
}

.container.style1 > .wrapper {
	display: inline-block;
	width: 41rem;
	background-color: var(--color-backgroundSecondary);
	-webkit-backdrop-filter: blur(0.2rem);
	backdrop-filter: blur(0.2rem);
	border-color: rgba(255,255,255,0.129);
	border-style: solid;
	border-radius: 0.75rem;
	/* border: #fff 2px solid; */
}

.container.style1 > .wrapper > .inner {
	--gutters: 0.5rem;
	--padding-horizontal: 1rem;
	--padding-vertical: 1rem;
	padding: var(--padding-vertical) var(--padding-horizontal);
	border-radius: calc(0.75rem - 1px);
}

.container.style1.default > .wrapper > .inner > * {
	margin-bottom: var(--spacing);
	margin-top: var(--spacing);
}

.container.style1.default > .wrapper > .inner > *:first-child {
	margin-top: 0 !important;
}

.container.style1.default > .wrapper > .inner > *:last-child {
	margin-bottom: 0 !important;
}

.container.style1.columns > .wrapper > .inner {
	flex-wrap: wrap;
	display: flex;
	align-items: center;
}

.container.style1.columns > .wrapper > .inner > * {
	flex-grow: 0;
	flex-shrink: 0;
	max-width: 100%;
	text-align: var(--alignment);
	padding: 0 0 0 var(--gutters);
}

.container.style1.columns > .wrapper > .inner > *:first-child {
	margin-left: calc(var(--gutters) * -1);
}

.container.style1.columns > .wrapper > .inner > *:before {
	border-left: solid 1px rgba(255,255,255,0.129);
	content: '';
	display: block;
	margin-left: calc((var(--gutters) * -0.5) - 0.5px);
	position: absolute;
	width: 0;
	height: calc(100% - (var(--padding-vertical) * 2));
	top: var(--padding-vertical);
}

.container.style1.columns > .wrapper > .inner > *:first-child:before {
	display: none;
}

.container.style1.default > .wrapper > .inner > .full:last-child {
	margin-bottom: calc(var(--padding-vertical) * -1) !important;
	border-bottom-left-radius: inherit;
	border-bottom-right-radius: inherit;
}

.container.style1.columns > .wrapper > .inner > div > .full {
	margin-left: calc((var(--gutters) * -0.5) + 0.5px);
	max-width: none !important;
	width: calc(100% + var(--gutters) + 0.4725px - 1px);
}



#container05 > .wrapper > .inner > :nth-child(1) {
	width: calc(33.33333% + (var(--gutters) / 3));
}

#container05 > .wrapper > .inner > :nth-child(2) {
	width: calc(33.33333% + (var(--gutters) / 3));
}

#container05 > .wrapper > .inner > :nth-child(3) {
	width: calc(33.33333% + (var(--gutters) / 3));
}

hr {
	align-items: center;
	border: 0;
	display: flex;
	justify-content: var(--flex-alignment);
	min-height: 1rem;
	padding: 0;
	position: relative;
	width: 100%;
}

hr:before {
	content: '';
}

hr.style1:not(:first-child) {
	margin-top: 4.25rem !important;
}

hr.style1:not(:last-child) {
	margin-bottom: 4.25rem !important;
}

hr.style1:before {
	width: 100%;
	background-color: rgba(255,255,255,0.09);
	height: 1px;
}

.list {
	display: block;
}

.list ul, .list ol {
	display: inline-block;
	max-width: 100%;
	text-align: var(--site-language-alignment);
	vertical-align: middle;
}

.list ul li, .list ol li {
	direction: var(--site-language-direction);
	display: flex;
	position: relative;
}

.list ul li:before, .list ol li:before {
	background-repeat: no-repeat;
	content: '';
	display: block;
	flex-grow: 0;
	flex-shrink: 0;
	font-variant: normal !important;
	letter-spacing: 0 !important;
	order: 1;
	position: relative;
}

.list ul li:after, .list ol li:after {
	content: '';
	display: block;
	flex-grow: 0;
	flex-shrink: 0;
	order: 2;
	pointer-events: none;
}

.list ul li p, .list ol li p {
	flex-grow: 1;
	flex-shrink: 1;
	order: 3;
}

.list ul li:first-child, .list ol li:first-child {
	margin-top: 0 !important;
}

form {
	display: flex;
	justify-content: var(--flex-alignment);
}

form .inner {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	max-width: 100%;
}

form label {
	direction: var(--site-language-direction);
	display: block;
}

form .field[data-type="label"] + div {
	margin-top: 0 !important;
}

form .field button {
	align-items: center;
	background-color: transparent;
	border: 0;
	cursor: pointer;
	display: flex;
	justify-content: center;
	position: absolute;
}

form .field button > svg {
	height: 50%;
	min-width: 100%;
}

form input[type="text"], form input[type="email"], form input[type="tel"], form input[type="number"], form textarea, form select, form .file, form .date {
	background-color: transparent;
	border: 0;
	direction: var(--site-language-direction);
	display: block;
	outline: 0;
	text-align: var(--site-language-alignment);
	width: 100%;
}

form input[type="tel"] {
	-webkit-appearance: none;
}

form textarea {
	height: 10rem;
	line-height: normal;
}

form select {
	background-repeat: no-repeat;
	background-size: 1rem;
	text-overflow: ellipsis;
	-webkit-appearance: none;
}

form select option {
	background-color: white;
	color: black;
}

form select::-ms-expand {
	display: none;
}

form p {
	display: block;
}

form .actions {
	max-width: 100%;
}

form .actions button {
	align-items: center;
	background-color: transparent;
	border: 0;
	cursor: pointer;
	display: inline-flex;
	justify-content: center;
	max-width: 100%;
	text-align: center;
	vertical-align: middle;
	white-space: nowrap;
}

@keyframes button-spinner {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

#form01 .inner > * {
	margin: 1rem 0 0 0;
	max-width: 100%;
}

#form01 .inner > :first-child {
	margin: 0;
}

#form01 .inner {
	width: 25rem;
}

#form01 label:first-child {
	margin: 0.25rem 0 0.75rem 0;
	font-size: 1em;
	line-height: 1.5;
	font-family: 'Inter', sans-serif;
	font-weight: 600;
	color: #FFFFFF;
}

#form01 .field button {
	background-size: 1.4rem;
	height: 2.8rem;
	line-height: 2.8rem;
	width: 2.8rem;
	border-radius: 0.56rem;
	background-color: #007AFF;
	transition: color 0.25s ease, background-color 0.25s ease, border-color 0.25s ease;
}

#form01 .field button svg {
	fill: #FFFFFF;
	transition: fill 0.25s ease;
}

#form01 input[type="text"], #form01 input[type="email"], #form01 input[type="tel"], #form01 input[type="number"], #form01 textarea, #form01 select, #form01 .file, #form01 .date {
	font-size: 1em;
	font-family: 'Inter', sans-serif;
	font-weight: 400;
	border-radius: 0.875rem;
	color: #FFFFFF;
	background-color: var(--color-backgroundSecondary);
}

#form01 input[type="text"], #form01 input[type="email"], #form01 input[type="tel"], #form01 input[type="number"], #form01 select, #form01 .file, #form01 .date {
	height: 3.5rem;
	padding: 0 1.225rem;
	line-height: 3.5rem;
}

#form01 textarea {
	padding: 1.225rem;
	height: 10rem;
	line-height: 1.5;
	padding-top: 0.9625rem;
}

#form01 .actions button {
	display: inline-block;
	height: 3.5rem;
	line-height: 3.5rem;
	padding: 0 1.75rem;
	font-size: 1em;
	font-family: 'Inter', sans-serif;
	font-weight: 600;
	border-radius: 2.5rem;
	direction: var(--site-language-direction);
	overflow: hidden;
	text-overflow: ellipsis;
	background-color: #007AFF;
	color: #FFFFFF;
	transition: color 0.25s ease, background-color 0.25s ease, border-color 0.25s ease;
	position: relative;
}

#form01 .inner > :first-child > label:first-child {
	margin-top: 0;
}

#form01 .actions button:before {
	animation: button-spinner 1s infinite linear;
	background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iOTZweCIgaGVpZ2h0PSI5NnB4IiB2aWV3Qm94PSIwIDAgOTYgOTYiIHpvb21BbmRQYW49ImRpc2FibGUiPjxzdHlsZT5jaXJjbGUge2ZpbGw6IHRyYW5zcGFyZW50OyBzdHJva2U6ICNGRkZGRkY7IHN0cm9rZS13aWR0aDogMnB4OyB9PC9zdHlsZT48ZGVmcz48Y2xpcFBhdGggaWQ9ImNvcm5lciI+PHBvbHlnb24gcG9pbnRzPSIwLDAgNDgsMCA0OCw0OCA5Niw0OCA5Niw5NiAwLDk2IiAvPjwvY2xpcFBhdGg+PC9kZWZzPjxnIGNsaXAtcGF0aD0idXJsKCNjb3JuZXIpIj48Y2lyY2xlIGN4PSI0OCIgY3k9IjQ4IiByPSIzMiIvPjwvZz48L3N2Zz4=');
	background-position: center;
	background-repeat: no-repeat;
	background-size: 2.625rem;
	content: '';
	display: block;
	height: 2.625rem;
	left: 50%;
	margin: -1.3125rem 0 0 -1.3125rem;
	opacity: 0;
	pointer-events: none;
	position: absolute;
	top: 50%;
	transition: opacity 0.25s ease;
	transition-delay: 0s;
	width: 2.625rem;
}

#form01 .actions button.waiting {
	color: transparent;
}

#form01 .actions button.waiting svg {
	fill: transparent;
}

#form01 .actions button.waiting:before {
	opacity: 1.0;
	transition-delay: 0.125s;
}

#form01 ::-webkit-input-placeholder {
	color: #FFFFFF;
	opacity: 0.55;
}

#form01 :-moz-placeholder {
	color: #FFFFFF;
	opacity: 0.55;
}

#form01 ::-moz-placeholder {
	color: #FFFFFF;
	opacity: 0.55;
}

#form01 :-ms-input-placeholder {
	color: #FFFFFF;
	opacity: 0.55;
}

#form01 .file[data-filename=""]:before {
	color: #FFFFFF;
	opacity: 0.55;
}

#form01 .date[data-value=""]:before {
	color: #FFFFFF;
	opacity: 0.55;
}

.bubbles__container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
	padding-bottom: 20px;
  flex-direction: row; 
	justify-content: center;
  align-items: stretch; 
  width: 100%;
  flex-wrap: wrap;
}

.bubbles {
  transition: all 0.4s ease;
  display: flex; 
	flex: 1;
  flex-direction: column;
  background-color: var(--color-backgroundSecondary);
  padding: 25px;
  border-radius: 15px;
  width: 80%;
  min-width: 250px;
  max-width: 350px;
}

.bubbles_subheader {
  color: rgba(255,255,255,1);
  font-size: 18px; 
  font-weight: 600;
	font-family: 'Inter', sans-serif;
  /* text-transform: capitalize; */
}

.bubbles_text {
  color: rgba(255,255,255,1);
  font-size: 16px; 
  font-weight: 200;
  line-height: 140%;
  margin-top: 10px;
  text-align: center;
	font-family: 'Inter', sans-serif;
}

@media (max-width: 1920px) {
	
}

@media (max-width: 1680px) {
	html {
		font-size: 13pt;
	}
}

@media (max-width: 1280px) {
	html {
		font-size: 13pt;
	}
}

@media (max-width: 1024px) {
	
}

@media (max-width: 980px) {
	html {
		font-size: 11pt;
	}
}

@media (max-width: 736px) {
	html {
		font-size: 11pt;
	}

	.heroSpacer {
		height: 30px;	
	}
	
	#main > .inner {
		--padding-horizontal: 1rem;
		--padding-vertical: 4rem;
		--spacing: 0rem;
	}
	
	h1.style3:not(:first-child), h2.style3:not(:first-child), h3.style3:not(:first-child), p.style3:not(:first-child) {
		margin-top: 1.5rem !important;
	}
	
	h1.style3:not(:last-child), h2.style3:not(:last-child), h3.style3:not(:last-child), p.style3:not(:last-child) {
		margin-bottom: 1.5rem !important;
	}
	
	h1.style3, h2.style3, h3.style3, p.style3 {
		letter-spacing: 0rem;
		width: 100%;
		font-size: 2.5em;
		line-height: 1.125;
	}
	
	h1.style9:not(:first-child), h2.style9:not(:first-child), h3.style9:not(:first-child), p.style9:not(:first-child) {
		margin-top: 2rem !important;
	}
	
	h1.style9:not(:last-child), h2.style9:not(:last-child), h3.style9:not(:last-child), p.style9:not(:last-child) {
		margin-bottom: 2rem !important;
	}
	
	h1.style9, h2.style9, h3.style9, p.style9 {
		letter-spacing: 0rem;
		width: 100%;
		font-size: 1.25em;
		line-height: 1.375;
	}
	
	h1.style4, h2.style4, h3.style4, p.style4 {
		letter-spacing: 0rem;
		width: 100%;
		font-size: 1em;
		line-height: 1.5;
	}
	
	h1.style1, h2.style1, h3.style1, p.style1 {
		letter-spacing: 0rem;
		width: 100%;
		font-size: 1em;
		line-height: 1.375;
	}
	
	h1.style6:not(:first-child), h2.style6:not(:first-child), h3.style6:not(:first-child), p.style6:not(:first-child) {
		margin-top: 1.125rem !important;
	}
	
	h1.style6:not(:last-child), h2.style6:not(:last-child), h3.style6:not(:last-child), p.style6:not(:last-child) {
		margin-bottom: 1.125rem !important;
	}
	
	h1.style6, h2.style6, h3.style6, p.style6 {
		letter-spacing: 0rem;
		width: 100%;
		font-size: 1.125em;
		line-height: 1.375;
	}
	
	h1.style5, h2.style5, h3.style5, p.style5, li.style5 {
		letter-spacing: 0rem;
		width: 100%;
		font-size: 1em;
		line-height: 1.375;
	}

	li.style5 {
		line-height: 1.7;
	}
	
	h1.style2:not(:first-child), h2.style2:not(:first-child), h3.style2:not(:first-child), p.style2:not(:first-child) {
		margin-top: 2rem !important;
	}
	
	h1.style2:not(:last-child), h2.style2:not(:last-child), h3.style2:not(:last-child), p.style2:not(:last-child) {
		margin-bottom: 2rem !important;
	}
	
	h1.style2, h2.style2, h3.style2, p.style2 {
		letter-spacing: 0rem;
		width: 100%;
		font-size: 1em;
		line-height: 1.5;
	}
	
	.buttons.style2 {
		gap: 0.75rem;
	}
	
	.buttons.style2:not(:first-child) {
		margin-top: 1.5rem !important;
	}
	
	.buttons.style2:not(:last-child) {
		margin-bottom: 1.5rem !important;
	}
	
	.buttons.style2 li a {
		letter-spacing: 0rem;
		font-size: 1em;
	}
	
	.buttons.style2 li a svg {
		width: 1em;
	}
	
	.image.style1 .frame {
		width: 20.875rem;
	}

	#container07 > .wrapper > .inner > :nth-child(1) {
		--alignment: center;
		--flex-alignment: center;
		--indent-left: 1;
		--indent-right: 1;
		--margin-left: auto;
		--margin-right: auto;
		min-height: 100% !important;
		width: 100% !important;
	}
	
	#container07 > .wrapper > .inner > :nth-child(2) {
		--alignment: center;
		--flex-alignment: center;
		--indent-left: 1;
		--indent-right: 1;
		--margin-left: auto;
		--margin-right: auto;
		min-height: 100% !important;
		width: 100% !important;
	}
	
	.container.style1 {
		--alignment: center;
		--flex-alignment: center;
		--indent-left: 1;
		--indent-right: 1;
		--margin-left: auto;
		--margin-right: auto;
	}
	
	.container.style1 > .wrapper > .inner {
		--gutters: 0.5rem;
		--padding-horizontal: 1rem;
		--padding-vertical: 1rem;
	}
	
	#container05 > .wrapper > .inner > :nth-child(1) {
		--alignment: center;
		--flex-alignment: center;
		--indent-left: 1;
		--indent-right: 1;
		--margin-left: auto;
		--margin-right: auto;
	}
	
	#container05 > .wrapper > .inner > :nth-child(2) {
		--alignment: center;
		--flex-alignment: center;
		--indent-left: 1;
		--indent-right: 1;
		--margin-left: auto;
		--margin-right: auto;
	}
	
	#container05 > .wrapper > .inner > :nth-child(3) {
		--alignment: center;
		--flex-alignment: center;
		--indent-left: 1;
		--indent-right: 1;
		--margin-left: auto;
		--margin-right: auto;
	}
	
	hr.style1:not(:first-child) {
		margin-top: 4rem !important;
	}
	
	hr.style1:not(:last-child) {
		margin-bottom: 4rem !important;
	}

	#form01 input[type="text"], #form01 input[type="email"], #form01 input[type="tel"], #form01 input[type="number"], #form01 textarea, #form01 select, #form01 .file, #form01 .date {
		font-size: 1em;
		letter-spacing: 0rem;
	}
	
	#form01 textarea {
		line-height: 1.5;
		padding-top: 0.9625rem;
	}
	
	#form01 .actions button {
		font-size: 1em;
		letter-spacing: 0rem;
	}
}

@media (max-width: 480px) {
	#main > .inner {
		--spacing: 0rem;
	}
	
	.buttons.style2 li a {
		width: 30rem;
	}
	
	.image.style1 .frame {
		width: 90%;
	}
	
	#form01 .actions button {
		max-width: 32rem;
		width: 100%;
	}
}

@media (max-width: 360px) {
	#main > .inner {
		--padding-horizontal: 0.75rem;
		--padding-vertical: 3rem;
		--spacing: 0rem;
	}
	
	.style3 {
    font-size: 2.5em;
	}

	.style9 {
			font-size: 1.25em;
	}

	.style4,
	.style1,
	.style5,
	.style2 {
			font-size: 1em;
	}

	.style6 {
			font-size: 1.125em;
	}
	
	.buttons.style2 {
		gap: 0.5625rem;
	}
	
	.container.style2 > .wrapper > .inner {
		--gutters: 0.375rem;
		--padding-horizontal: 0.75rem;
		--padding-vertical: 0.75rem;
	}
	
	.container.style1 > .wrapper > .inner {
		--gutters: 0.375rem;
		--padding-horizontal: 0.75rem;
		--padding-vertical: 0.75rem;
	}

}