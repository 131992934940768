/* Importing Google Font Family */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

/* Root Variables for css */
:root {
  /* colors */
  --banner-btn-bg: linear-gradient(90deg, #9e6ee5 0%, #fa75f8 100%);
  --input-bg: linear-gradient(90deg, #fff 0%, #fff 100%);
  --banner-overlay-one: radial-gradient(
    50% 50% at 50% 50%,
    rgba(158, 110, 230, 0.28) 0%,
    rgba(158, 110, 230, 0) 100%
  );
  --banner-overlay-two: radial-gradient(
    50% 50% at 50% 50%,
    rgba(92, 195, 250, 0.28) 0%,
    rgba(92, 195, 250, 0) 100%
  );
  --banner-overlay-right: radial-gradient(
    50% 50% at 50% 50%,
    rgba(250, 117, 248, 0.28) 0%,
    rgba(250, 117, 248, 0) 100%
  );
  --input-text: #121212;
  --input-text-placeholder: rgba(0,0,0,0.5);
  --gray-text: #e9e9e9;



  /* border radius */
  --six-pixel: 6px;

  /* Box Shadow */
  --inputBox-shadow: 0px 1px 1.5px 0px rgba(0, 0, 0, 0.05),
    0px 2px 4px 0px rgba(0, 0, 0, 0.07), 0px 0px 0px 1px #e0e0e0;

  --billint-btn-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.07),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1),
    0px 0px 0px 1px rgba(50, 50, 93, 0.1) inset;

  /* font family */
  --roboto-font: "Roboto", sans-serif;
}

/* Default Css Reseting */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  -webkit-tap-highlight-color: transparent;
  scroll-behavior: smooth;
}

body {
  font-family: "Inter", sans-serif;
  background-color: #000;
}

button {
  font-family: "Inter", sans-serif;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* elements reseting */
h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
span {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

/* Reusble Classes */
.container {
  max-width: 1400px;
  margin: 0 auto;
  height: "100%";
  align-items: center;
  display: flex;
  flex-direction: column;
  /* width: 70%;
  max-width: 800px;
  min-width: 350px; */
}

.container-sm {
  max-width: 920px;
  margin: 0 auto;
}

/* Overlays Css */
.banner_left__overlay {
  width: 1845px;
  height: 1000px;
  opacity: 0.8;
  background: var(--banner-overlay-one);
  position: absolute;
  top: -5%;
  left: -25%;
  z-index: -1;
}

.banner_left__overlay_two {
  width: 1845px;
  height: 1000px;
  opacity: 0.8;
  background: var(--banner-overlay-two);
  position: absolute;
  bottom: -50%;
  left: -50%;
  z-index: -2;
}

.banner_right__overlay {
  width: 1845px;
  height: 1000px;
  background: var(--banner-overlay-right);
  opacity: .7;
  position: absolute;
  right: -45%;
  bottom: -35%;
  z-index: -1;
}


/* 
========================================================================
HomePage Css Start
========================================================================
*/

.home {
  background-color: #000;
  min-height: 100vh;
}

nav {
  padding: 24px 0;
  text-align: center;
}

nav p {
  color: #fff;
  font-size: 18px;
  letter-spacing: 0px;
  font-weight: 500;
}

.banner {
  padding-top: 50px;
  position: relative;
  overflow: hidden;
  isolation: isolate;
  min-height: 100vh
}

.banner_heading {
  color: #fff;
  text-align: center;
  font-size: 75px;
  font-weight: 400;
  line-height: 126%;
  width: 90%;
  max-width: 800px;
  min-width: 350px;
}

.banner_heading_2 {
  /* color: #fff; */
  color: rgba(255,255,255,1);
  text-align: center;
  font-size: 40px;
  font-weight: 200;
  line-height: 126%;
  margin-top: 120px;
  width: 70%;
  max-width: 600px;
  min-width: 350px;
  /* display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; */
  /* background-image: linear-gradient(to right, transparent, #9e6ee5, #C65EC6, transparent);
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 30px; */
}

.banner_subhead {
  color: rgba(255, 255, 255, 0.9);
  font-weight: 200;
  text-align: center;
  font-size: 25px;
  line-height: 140%;
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  max-width: 720px;
  min-width: 350px;
}

.banner__inputBox {
  padding-top: 15px;
  width: 380px;
  margin: 0 auto;
  max-width: 350px;


}

.banner_input {
  width: 100%;
  height: 60px;
  padding: 18px;
  background: var(--input-bg);
  border: none;
  border-radius: var(--six-pixel);
  outline: none;
  font-size: 17px;
  color: var(--input-text);
  
}

.banner_input::placeholder {
  color: var(--input-text-placeholder);
}

.input__submit_btn {
  width: 100%;
  height: 60px;
  background: var(--banner-btn-bg);
  border: none;
  border-radius: var(--six-pixel);
  margin-top: 21px;
  font-size: 17px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  position: relative;
  isolation: isolate;
  align-self: center;
}

.input__submit_btn::after {
  content: "";
  position: absolute;
  inset: 2px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: inherit;
  z-index: -1;
  opacity: 1;
  transition: all 0.3s ease;
}

.input__submit_btn:hover::after {
  opacity: 0;
}

.additional-text {
  color: var(--gray-text);
  font-size: 13px;
  text-align: center;
  margin-top: 13px;
  line-height: 110%;
}

.bubbles__container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-direction: row; 
    justify-content: center;
  align-items: stretch; 
  width: 100%;
  flex-wrap: wrap;
}



.pdf-container {
  position: relative;
  width: 220px;
  cursor: pointer;
  /* border-radius: 15px; */
  overflow: hidden;
  transition: all 0.2s ease-in-out;  
}

.pdf-container:hover {
  opacity: 0.5;
  scale: 1.05;
}


@media screen and (max-width: 1024px) {
  /* .banner {
    padding: 3%;
  } */

  /* .bubbles {
    width: 200px;
    height: 200px;
  } */

}

@media screen and (max-width: 768px) {
  .banner {
    padding: 5%;
  }

  nav p {
    font-size: 14px;
  }

  .banner_heading {
    font-size: 48px;
  }

  .banner_heading_2 {
    font-size: 28px;
    margin-top: 60px;
  }

  .banner_subhead {
    font-size: 20px;
  }

  .banner__inputBox {
    /* width: 50%; */
    width: 100%;
  }

  .banner_input {
    font-size: 18px;
  }

  .input__submit_btn {
    font-size: 18px;
  }

  .additional-text {
    font-size: 14px;
    color: rgba(255,255,255,.7)
  }

  .pdf-container {
    width: 150px;
  }

}



















  /* Checkout Pages Colors
  --checkout-bg: linear-gradient(to right, #111 50%, white 50%);
  --checkout-text-gray: rgba(255, 255, 255, 0.6);
  --package-box-border: rgba(255, 255, 255, 0.1);
  --package-desc: rgba(255, 255, 255, 0.5);
  --package-bottom-bg: rgba(255, 255, 255, 0.05);
  --switch-border: 1px solid rgba(0, 0, 0, 0.1);
  --switch-bg: rgba(255, 255, 255, 0.5);
  --billing-btn-bg: #f44425;
  --save-bg: #cbf4c9;
  --save-text: #0e6245;
  --subtotal-top-border: rgba(255, 255, 255, 0.1);
  --footer__link: rgba(255, 255, 255, 0.5);
  --checkout-right-heading: rgba(26, 26, 26, 0.9);
  --checkout-input-bg: #f7f7f7;
  --normal-placeholder: rgba(26, 26, 26, 0.5);
  --email--placeholder: rgba(26, 26, 26, 0.7);
  --checkout-input-text: rgba(26, 26, 26, 0.9);
  --optional-border: rgba(26, 26, 26, 0.2); */







/* 
========================================================================
Checkout Css Start
========================================================================
*/

/* .checkout {
  background: var(--checkout-bg);
  min-height: 100vh;
  font-family: var(--roboto-font) !important;
}

.checkout__content {
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 144px;
  padding: 110px 0;
}

.checkout_left,
.checkout_right {
  width: 50%;
}

.back_buttons {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 32px;
}

.checkout_text {
  color: var(--checkout-text-gray);
  font-size: 15px;
  font-weight: 500;
}

.bill {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
  margin-bottom: 45px;
}

.bill_amount {
  color: #fff;
  font-size: 34px;
  font-weight: 600;
  letter-spacing: -0.48px;
}

.bill_type {
  font-size: 14px;
  color: var(--checkout-text-gray);
  font-weight: 600;
  letter-spacing: -0.48px;
}

.package__box {
  width: 100%;
  padding: 19px 17px;
  border: 1px solid var(--package-box-border);
  border-bottom: none;
  border-radius: 6px 6px 0px 0px;
}

.package__heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.package_white {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 130%;
}

.package_desc {
  margin-top: 5px;
}

.package_desc_text {
  font-size: 12px;
  color: var(--package-desc);
  line-height: 130%;
}

.package__bottom {
  width: 100%;
  background: var(--package-bottom-bg);
  border-radius: 0px 0px 6px 6px;
  padding: 12px 16px;
  border: 1px solid var(--package-box-border);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.package__bottom::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 1px;
  border-radius: 0px 0px 7px 7px;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 35%,
    #24b47e 50%,
    rgba(255, 255, 255, 0) 65%
  );
}

.package__bottom::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 1px;
  border-radius: 0px 0px 7px 7px;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 35%,
    #24b47e 50%,
    rgba(255, 255, 255, 0) 65%
  );
}

.package_bottom_left {
  display: flex;
  align-items: center;
}

.switch {
  width: 32px;
  height: 18px;
  background: var(--switch-bg);
  border-radius: 20px;
  cursor: pointer;
  padding: 2px;
  display: flex;
  align-items: center;
  transition: all 0.2s ease-in-out;
  position: relative;
}

.switch.active {
  background: var(--billing-btn-bg);
}

.ball {
  width: 14px;
  height: 14px;
  border-radius: 100%;
  background: #fff;
  position: absolute;
  left: 2px;
  transition: all 0.2s ease-in-out;
}

.switch.active .ball {
  left: 16px;
}

.save_box {
  width: max-content;
  background: var(--save-bg);
  padding: 3px 6px;
  border-radius: 4px;
  margin-left: 8px;
}

.save_amount {
  color: var(--save-text);
  font-size: 12px;
  font-weight: 500;
}

.type_additional {
  font-size: 12px;
  color: #fff;
  font-weight: 500;
  margin-left: 4px;
}

.yearly__amount {
  font-size: 12px;
  font-weight: 400;
  color: #fff;
}

.subtotal__box {
  margin: 17px 15px 0 15px;
  border-top: 1px solid var(--subtotal-top-border);
  padding: 17px 0 200px 0;
}

.subtotal__amount {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
}

.sub_amount_text {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.48px;
}

.promo_code {
  width: max-content;
  background: var(--package-bottom-bg);
  padding: 10px 12px;
  border-radius: var(--six-pixel);
  margin-bottom: 16px;
}

.promo_text {
  font-size: 13px;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
}

.total_due {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 17px;
  border-top: 1px solid var(--subtotal-top-border);
}

.left__footer {
  display: flex;
  align-items: center;
  gap: 16px;
}

.left__footer a {
  color: var(--footer__link);
  font-size: 12px;
}

.checkout_r_heading {
  color: var(--checkout-right-heading);
  opacity: 0.4333;
  font-weight: 500;
  font-size: 18px;
}

.checkout_input__fields {
  margin-top: 42px;
}

.checkout__email {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  background: var(--checkout-input-bg);
  box-shadow: var(--inputBox-shadow);
  border-radius: var(--six-pixel);
}

.checkout__placeholder {
  color: var(--email--placeholder);
  font-size: 13px;
  width: 40%;
}

.checkout__input {
  border: none;
  outline: none;
  background: transparent;
  width: 60%;
  font-size: 14px;
  color: var(--checkout-input-text);
}

.checkout__input::placeholder {
  color: var(--checkout-input-text);
}

.card__info {
  margin-top: 20px;
}

.card__info_box {
  background: #fff;
  border-radius: 6px;

  margin-top: 8px;
}

.cardNumber::placeholder {
  color: var(--normal-placeholder);
}

.card__number {
  width: 100%;
  padding: 10px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e0e0e0;
  border-radius: 6px 6px 0px 0px;
}

.validate__cvc {
  display: flex;
}

.cardholder__name {
  margin-top: 19px;
}

.billing__address {
  margin-top: 19px;
  margin-bottom: 25px;
}

.country__box {
  padding: 10px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e0e0e0;
  border-radius: 6px 6px 0px 0px;
  cursor: pointer;
  position: relative;
}

.selected_country {
  font-size: 14px;
  color: var(--checkout-input-text);
}

.arrow {
  transition: all 0.2s ease;
}

.arrow.active {
  rotate: 180deg;
}

.country__dropdown {
  position: absolute;
  top: 103%;
  left: 0;
  z-index: 99;
  width: 100%;
  box-shadow: var(--inputBox-shadow);
  background: #fff;
  border-radius: 0px 0px 6px 6px;
  opacity: 0;
  visibility: hidden;
  scale: 0.8;
  transition: all 0.2s ease-in-out;
}

.country__dropdown.active {
  opacity: 1;
  visibility: visible;
  scale: 1;
}

.country__dropdown .selected_country {
  padding: 6px;
}

.country__dropdown .selected_country:not(:last-child) {
  border-bottom: 1px solid #e0e0e0;
}

.country__dropdown .selected_country:is(:first-child) {
  padding-top: 10px;
}

.manual__address {
  font-size: 12px;
  color: var(--email--placeholder);
  text-decoration-line: underline;
  text-underline-offset: 2px;
  margin-top: 8px;
}

.secure__box {
  border: 1px solid #e0e0e0;
  border-radius: 6px 6px 0px 0px;
  padding: 10px 12px;
}

.secure__heading {
  font-size: 14px;
  font-weight: 500;
  color: var(--checkout-input-text);
}

.secure__desc {
  font-size: 13px;
  color: var(--email--placeholder);
  margin-top: 6px;
}

.phone__box {
  border: 1px solid #e0e0e0;
  border-top: none;
  border-radius: 0px 0px 6px 6px;
  padding: 10px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 8px;
}

.phone__box__left {
  display: flex;
  align-items: center;
  gap: 8px;
}

.optional_box {
  padding: 3px 4.8px 4.59px 5px;
  font-size: 12px;
  color: var(--normal-placeholder);
  font-weight: 500;
  border: 1px solid var(--optional-border);
  width: max-content;
  border-radius: 4px;
}

.checkout__linkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.checkout__linkbox .manual__address {
  margin-top: 0;
}

.card__pay__btn {
  border: none;
  outline: none;
  background-color: var(--billing-btn-bg);
  width: 100%;
  margin-top: 38px;
  padding: 13px 0px 14px 0px;
  border-radius: 6px;
  box-shadow: var(--billint-btn-shadow);
}

.card__pay__btn p {
  font-weight: 500;
  font-size: 15px;
  color: #fff;
  opacity: 0.6;
}

.process {
  font-size: 13px;
  color: var(--email--placeholder);
  line-height: 130%;
  margin-top: 14px;
} */




/* Testimonial Section
.testimonial {
  padding: 240px 0 400px 0;
  position: relative;
  isolation: isolate;
  overflow: hidden;
}

.testi_heading {
  background: linear-gradient(
    90deg,
    #fcba89 24.35%,
    #fa75f8 69.94%,
    #b463eb 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 40px;
  line-height: 138%;
  text-align: center;
  font-weight: 500;
}

.testi__content {
  padding-top: 135px;
  width: max-content;
  margin: 0 auto;
}

.testi__upper {
  background-color: var(--testi-top);
  padding: 2px 20px 2px 10px;
  margin-bottom: 2px;
}

.testi__text {
  font-size: 34px;
  color: #fff;
  line-height: 135%;
  font-weight: 400;
}

.author {
  width: max-content;
  display: flex;
  align-items: start;
  gap: 24px;
  padding-right: 115px;
  margin-top: 60px;
  border-right: 2px solid var(--author-border);
}

.author_name {
  color: #fff;
  font-size: 14px;
  margin-bottom: 10px;
}

.author_position {
  font-size: 14px;
  color: var(--author-position);
} */
